import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index'
import RichText from '../shared/RichText'


interface Props {
  textContent: {
    textTitle: string
    textContent: {
      json: string
    }
  }
}

export default function TextDetails(props: Props) {

  const {
    textContent,
  } = props

  const [isMobile, setIsMobile] = useState(
    typeof window != 'undefined' && window.innerWidth <= 1024 ? true : false,
  )

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
        return
      }
      setIsMobile(false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
    <Wrapper>
      <DetailsWrapper>
        {
          textContent.map(({textTitle, textContent}, index)=>(
            <Detail
              key={textTitle + index}
            >
              <DetailTitle>
                <p>
                  {`Section ${index + 1}`}
                </p>
                <h2>
                  {textTitle}
                </h2>
              </DetailTitle>
              <DetailText>
                <RichText
                  json={textContent.json}
                />
              </DetailText>
            </Detail>
          ))
        }
      </DetailsWrapper>
    </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
  position: relative;
  
  ${desktopBreakpoint}{
    padding-top: 0;
  }
`;

const DetailsWrapper = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;

  ${desktopBreakpoint}{
    height: auto;
    padding: ${desktopVW(69)} ${desktopVW(35)} 0 ${desktopVW(35)};
  }
`;

const Detail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.surface};


  ${desktopBreakpoint}{
    flex-direction: row;
  }
`;

const DetailTitle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em 0 2em 0;

  p{
    font-family: 'messinaMono', "Courier New", Courier, monospace;
    letter-spacing: -0.05em;
    color: ${colors.subtleGrey};
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)}; 
    }
  }

  h2{
    font-family: 'messinaMono', "Courier New", Courier, monospace;
    letter-spacing: -0.05em;
    color: ${colors.black};
    font-weight: 400;

    ${desktopBreakpoint}{
      font-size: ${desktopVW(30)}; 
    }
  }

  ${desktopBreakpoint}{
    width: 35%;
    padding: 0 ${desktopVW(50)} ${desktopVW(50)} 0;
  }
`;

const DetailText = styled.div`
  height: 100%;

  p{
    font-family: 'messinaMono', "Courier New", Courier, monospace;
    letter-spacing: -0.05em;
    color: ${colors.subtleGrey};
    line-height: 140%;
    font-size: ${mobileVW(18)};
    padding-bottom: 1em;  


    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)}; 

    }
  }

  ${desktopBreakpoint}{
    width: 70%;
    padding-bottom: 3em;  
  }
`;
