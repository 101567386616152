import React from 'react'
import { graphql } from 'gatsby'
import TextHero from '../components/text/TextHero'
import TextDetails from '../components/text/TextContent'
import SEO from '../components/shared/SEO'
import {useGlobalComponentsQuery} from '../hooks/index'


export interface textProps {
  data: {
    contentfulPageText: {
      slug: string
      title: string
      seoTitle: string
      seoDescription: string
      textContent: any
    }
  }
}

const Text = ({ data: { contentfulPageText } }: textProps) => {

  const {
    title,
    slug,
    seoTitle,
    seoDescription,
    textContent,
  } = contentfulPageText
  
  const global = useGlobalComponentsQuery()

  const isFaqPage = slug === "veelgestelde-vragen" || slug === "faq"
  const faqSchemaArray = isFaqPage && textContent.map(item => {
    return {
      "@type": "Question",
      "name": item.textTitle,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.textContent.json.content[0].content[0].value
      }
    }
  })
  const faqSchema = isFaqPage && 
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": faqSchemaArray
    }

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={global?.seoImage.file.url}
        schema={isFaqPage && faqSchema}
      />
      <TextHero title={title} />
      <TextDetails textContent={textContent} />
    </>
  );
}

export default Text

export const TextPageQuery = graphql`
  query TextPage($id: String!) {
    contentfulPageText(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      slug
      textContent {
        textTitle
        textContent {
          json
        }
      }
    }
  }
`