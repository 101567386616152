import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index'
import paper from '../../../static/paperBackground/paper.jpeg'



interface Props {
  title: string
}

export default function TextHero(props: Props) {

  const {
    title
  } = props

  return (
    <Wrapper>
      <h1>
        {title}
      </h1>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  h1{
    font-family: 'messina', Arial, Helvetica, sans-serif;
    line-height: 90%;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    z-index: ${zIndex.surface};
    font-size: ${mobileVW(35)};
    width: 100%;
    padding: ${mobileVW(110)} 0 0 0;

    ${desktopBreakpoint}{
      padding: 0;
      width: 70%;
      font-size: ${desktopVW(111)};
    }
  }
  
  
  ${desktopBreakpoint}{
    padding-top: ${desktopVW(151)};
  }
`;